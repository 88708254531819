import { createSettingsParams } from '@wix/tpa-settings';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ISection,
  SectionTypes,
  SidebarPosition,
} from './types';

export const defaultSections: ISection[] = [
  {
    type: SectionTypes.TITLE_TAGLINE,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DESCRIPTION,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DETAILS,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.POLICY,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.CONTACT,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.SCHEDULING,
    visible: true,
    bookButton: false,
  },
];

export default createSettingsParams<{
  policySectionTitle: string;
  sections: ISection[];
  headerVisibility: boolean;
  headerTitleVisibility: boolean;
  headerTitleAlignment: AlignmentOptions;
  headerImageVisibility: boolean;
  headerImagePosition: ImagePositionOptions;
  headerBookButtonVisibility: boolean;
  sidebarVisibility: boolean;
  onlineBadgeVisibility: boolean;
  onlineBadgeText: string;
  serviceTitleVisibility: boolean;
  serviceTaglineVisibility: boolean;
  titleAndTaglineVisibility: boolean;
  detailsDaysVisibility: boolean;
  detailsDurationVisibility: boolean;
  detailsPriceVisibility: boolean;
  detailsLocationVisibility: boolean;
  detailsBoxAlignment: AlignmentOptions | undefined;
  detailsTextAlignment: AlignmentOptions | undefined;
  detailsButtonAlignment: AlignmentOptions | undefined;
  titleAndTaglineAlignment: AlignmentOptions | undefined;
  businessEmailVisibility: boolean;
  businessPhoneNumberVisibility: boolean;
  businessAddressVisibility: boolean;
  contactDetailsTitleText: string;
  descriptionTitleText: string;
  bookButtonText: string;
  serviceUnavailableMessageText: string;
  fullCourseMessageText: string;
  unBookableCourseMessageText: string;
  sidebarSection: SectionTypes;
  sidebarFreezePosition: boolean;
  sidebarPosition: SidebarPosition;
  sidebarAlignment: AlignmentOptions;
  sidebarWidth: number;
  sidebarSideSpacing: number;
  bodyAlignment: AlignmentOptions;
  scheduleDays: number;
  scheduleTitleText: string;
  scheduleLoadAllText: string;
  scheduleEmptyStateText: string;
  scheduleDurationVisibility: boolean;
  scheduleStaffVisibility: boolean;
  showSessionLimit: boolean;
  scheduleSessionLimit: number;
}>({
  sidebarWidth: {
    getDefaultValue: () => 30,
  },
  sections: {
    getDefaultValue: () => defaultSections,
  },
  headerVisibility: {
    getDefaultValue: () => true,
  },
  headerTitleVisibility: {
    getDefaultValue: () => true,
  },
  headerTitleAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  headerImageVisibility: {
    getDefaultValue: () => true,
  },
  headerImagePosition: {
    getDefaultValue: () => ImagePositionOptions.MIDDLE,
  },
  headerBookButtonVisibility: {
    getDefaultValue: () => true,
  },
  sidebarVisibility: {
    getDefaultValue: () => false,
  },
  sidebarSection: {
    getDefaultValue: () => SectionTypes.DETAILS,
  },
  sidebarFreezePosition: {
    getDefaultValue: () => false,
  },
  sidebarSideSpacing: {
    getDefaultValue: () => 0,
  },
  sidebarPosition: {
    getDefaultValue: () => SidebarPosition.RIGHT,
  },
  sidebarAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.LEFT,
  },
  onlineBadgeVisibility: {
    getDefaultValue: () => true,
  },
  serviceTitleVisibility: {
    getDefaultValue: () => true,
  },
  titleAndTaglineVisibility: {
    getDefaultValue: () => true,
  },
  serviceTaglineVisibility: {
    getDefaultValue: () => true,
  },
  titleAndTaglineAlignment: {
    getDefaultValue: () => undefined,
  },
  policySectionTitle: {
    getDefaultValue: () => '',
  },
  detailsDaysVisibility: {
    getDefaultValue: () => true,
  },
  detailsDurationVisibility: {
    getDefaultValue: () => true,
  },
  detailsPriceVisibility: {
    getDefaultValue: () => true,
  },
  detailsLocationVisibility: {
    getDefaultValue: () => true,
  },
  detailsBoxAlignment: {
    getDefaultValue: () => undefined,
  },
  detailsTextAlignment: {
    getDefaultValue: () => undefined,
  },
  detailsButtonAlignment: {
    getDefaultValue: () => undefined,
  },
  businessEmailVisibility: {
    getDefaultValue: () => true,
  },
  businessPhoneNumberVisibility: {
    getDefaultValue: () => true,
  },
  businessAddressVisibility: {
    getDefaultValue: () => true,
  },
  contactDetailsTitleText: {
    getDefaultValue: () => '',
  },
  descriptionTitleText: {
    getDefaultValue: () => '',
  },
  onlineBadgeText: {
    getDefaultValue: () => '',
  },
  bookButtonText: {
    getDefaultValue: () => '',
  },
  serviceUnavailableMessageText: {
    getDefaultValue: () => '',
  },
  fullCourseMessageText: {
    getDefaultValue: () => '',
  },
  unBookableCourseMessageText: {
    getDefaultValue: () => '',
  },
  bodyAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.LEFT,
  },
  scheduleDays: {
    getDefaultValue: () => 7,
  },
  scheduleTitleText: {
    getDefaultValue: () => '',
  },
  scheduleLoadAllText: {
    getDefaultValue: () => '',
  },
  scheduleEmptyStateText: {
    getDefaultValue: () => '',
  },
  scheduleDurationVisibility: {
    getDefaultValue: () => true,
  },
  scheduleStaffVisibility: {
    getDefaultValue: () => true,
  },
  showSessionLimit: {
    getDefaultValue: () => true,
  },
  scheduleSessionLimit: {
    getDefaultValue: () => 3,
  },
});
