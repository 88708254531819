export const biDefaults = {
  appName: 'service_page',
  page_name: 'service_page',
  bookingsPlatform: 'new_bookings_server',
};

export const enum BITabName {
  MANAGE = 'service_page/manage',
  LAYOUT = 'service_page/layout',
  SECTIONS_TITLE = 'service_page/sections/title',
  SECTIONS_DESCRIPTION = 'service_page/sections/description',
  SECTIONS_DETAILS = 'service_page/sections/details',
  SECTIONS_POLICY = 'service_page/sections/policy',
  SECTIONS_CONTACT = 'service_page/sections/contact',
  SECTIONS_SCHEDULE = 'service_page/sections/schedule',
  DESIGN_BODY = 'service_page/design/body',
  DESIGN_HEADER = 'service_page/design/header',
  DESIGN_SIDEBAR = 'service_page/design/sidebar',
}
